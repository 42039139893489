// Migrated
<template>
  <div class="charter-page-layout">
    <LayoutWrapper>
      <Header />
      <CharterMobileMenu />
      <SolresorBanner
        :background="bannerImage"
        :title="bannerTitle"
        :subtitle="bannerSubtitle"
        :img-align="bannerBackground?.align || DEFAULT_IMAGE_ALIGN"
        banner-type="CAMPAIGN"
        :offline="true"
        :gradient-mask="gradientMask"
      />
      <template #solresorContent>
        <slot />
      </template>
      <template #footer>
        <LazyFooter />
      </template>
    </LayoutWrapper>
  </div>
</template>

<script setup>
const { getLocaleMessages: localeMessages } = useLocaleStore()

const DEFAULT_IMAGE_ALIGN = '50% 40%'
const { t } = useI18n()

const { public: { images: imagesConfig } } = useRuntimeConfig()

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,

  // link: () => [
  //   {
  //     rel: 'icon',
  //     type: 'image/x-icon',
  //     href: t('favicon')
  //   }
  // ],

  script: [{
    innerHTML: () => localeMessages.organizationData,
    type: 'application/ld+json',
  }],

  meta: [
    {
      hid: 'author',
      name: 'author',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => t('siteTitleBrand'),
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => t('siteTitleBrand'),
    },
  ],
})

const rootStore = useRootStore()
const {
  pageBannerTitle: bannerTitle,
  pageBannerSubtitle: bannerSubtitle,
  pageBannerBackground: bannerBackground,
  pageGradientMask: gradientMask,
} = storeToRefs(rootStore)

const bannerImage = computed(() => (
  bannerBackground.value?.image?.url ||
  bannerBackground.value?.image?.src ||
  bannerBackground.value?.picture_cloudinary ||
  imagesConfig.fallbackHeroId
))
</script>

<style lang="scss">
.charter-page-layout {
  .layout-container,
  .banner-layout.container-content {
    max-width: $container-width !important;

    @media (max-width: $container-width) {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
}
</style>
