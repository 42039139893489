// Migrated
<template>
  <ResponsiveBackground
    :image="image"
    :height="imgHeight"
    :align="imgAlign"
    :class="['page-title', `banner-type--${bannerType.toLowerCase()}`, gradientMask || 'no-gradient-mask', `${false ? 'with-gradient-overlay' : ''}`]"
  >
    <div class="w-full px-4 container-content mx-auto">
      <div class="flex flex-wrap -mx-4">
        <div class="xl:w-10/12 px-4 w-12/12 mx-auto">
          <div class="flex flex-wrap -mx-4 justify-between">
            <div
              v-if="!offline && !$isSol"
              class="xl:w-3/12 lg:w-3/12 px-4"
            />
            <div :class="contentClasses">
              <div class="page-title-content">
                <template v-if="bannerType === 'CAMPAIGN'">
                  <h4 v-show="subtitle">
                    {{ subtitle }}
                  </h4>
                  <h1 v-show="title">
                    {{ title }}
                  </h1>
                </template>
                <template v-else>
                  <h1 v-show="title">
                    {{ title }}
                  </h1>
                  <h4 v-show="subtitle">
                    {{ subtitle }}
                  </h4>
                </template>
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ResponsiveBackground>
</template>

<script>
import hotelImagesMixin, { supplierURLs } from '@packages/media/mixins/hotel-images.mixin'

export default defineNuxtComponent({
  mixins: [hotelImagesMixin],

  props: {
    background: {
      type: [String, Array, Object],
      required: true,
    },

    title: {
      type: String,
      default: null,
    },

    subtitle: {
      type: String,
      default: null,
    },

    bannerType: {
      type: String,
      default: 'CAMPAIGN',
    },

    imgHeight: {
      type: Number,
      default: 420,
    },

    imgAlign: {
      type: String,
      default: 'center',
    },

    offline: {
      type: Boolean,
      default: false,
    },

    gradientMask: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      imageType: 'cloudinary',
      image: '',
    }
  },

  computed: {
    contentClasses () {
      return [
        `align-self-${this.bannerType === 'CAMPAIGN' ? 'center' : 'end'}`,
        `justify-self-${this.bannerType === 'CAMPAIGN' ? 'center' : 'end'}`,
        ...(!this.$isSol ? ['xl:w-9/12', 'lg:w-9/12'] : ['w-12/12']),
      ]
    },
  },

  watch: {
    background () {
      this.evalImage()
    },
  },

  mounted () {
    this.evalImage()
  },

  methods: {
    evalImage () {
      if (Array.isArray(this.background)) {
        this.imageType = 'url'
        this.image = (
          this.background.find(i => i?.maxwidth >= 1440)
          || this.background[this.background.length - 1]
        ).href
        return
      }

      if (this.background instanceof Object) {
        const i = this.background
        if (Array.isArray(i?.image)) {
          const arrayImg = (
            i.image.find(i => i?.maxwidth >= 1440)
            || i.image[i.length - 1]
          )
          if (arrayImg?.href) {
            return arrayImg?.href
          }
        }
        this.imageType = i?.image_type || this.hotel.meta.images_type || this.hotel.supplier
        const url = supplierURLs[this.imageType]
          .replace('[PATH]', i?.image || i)
        this.image = this.imageSize(url, 'og')
        return
      }

      this.imageType = 'cloudinary'
      this.image = this.background
    },
  },
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/_page-title";
.no-gradient-mask {
  &::before {
    display: none;
  }
}
</style>
